import { createUrlTemplatedVariable, isImageCDNUrl } from '@srgssr/rio-shared';

export const loadImages = (urls: string[]) =>
  Promise.all<HTMLImageElement>(
    urls.map(
      (url) =>
        new Promise((res, rej) => {
          const img = new Image();
          img.onload = () => res(img);
          img.onerror = () => rej(img);
          img.src = url;
        })
    )
  );

export const setImageDimensions = (url: string, width: number, height: number) => {
  if (isImageCDNUrl(url)) {
    return url;
  }

  return url
    .replace(createUrlTemplatedVariable('width'), `${Math.floor(width)}`)
    .replace(createUrlTemplatedVariable('height'), `${Math.floor(height)}`);
};

export const setImageDimensionsAndPreload = async (url: string, width: number, height: number) => {
  const urlWithDimensions = setImageDimensions(url, width, height);
  if (urlWithDimensions) await loadImages([urlWithDimensions]).catch(() => {});
  return urlWithDimensions;
};
