import React, { forwardRef, memo, PropsWithChildren, useEffect } from 'react';

import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useDidMount } from '@srgssr/rio-common/react';
import styled, { css } from 'styled-components';

import { FirstParameter, PropsWithClassName } from '../../types';
import { FocusBlurHandler, PressHandler } from '../../types/focus';
import { setComponentRefs } from '../../utils';
import { DEFAULT_ANIMATION_DURATION, EASE } from '../../utils/animation';
import { setImageDimensions } from '../../utils/image';
import { RATIO, scaleX } from '../../utils/window';

export const TEASER_WIDTH = scaleX(375, null);
export const TEASER_HEIGHT = Math.ceil(TEASER_WIDTH * RATIO);
export const TEASER_FOCUS_INDICATOR_SIZE = scaleX(7, null);

const BORDER_RADIUS = scaleX(8);

export type TeaserRef = HTMLElement | null;

type OnFocusBlur = FocusBlurHandler<{ index: number }>;
type OnMount = (params: Pick<FirstParameter<OnFocusBlur>, 'index' | 'focusKey'>) => void;
type OnUpdate = (params: Pick<FirstParameter<OnFocusBlur>, 'index'>) => void;
type OnPress = PressHandler<Pick<FirstParameter<OnFocusBlur>, 'index'>>;

export type TeaserProps = PropsWithClassName<
  PropsWithChildren<{
    index: number;
    imageUrl: string;
    ranking?: boolean;
    $width?: number;
    $height?: number;
    opacity?: number;
    hasTVPreferredFocus?: boolean;
    onMount?: OnMount;
    onUpdate?: OnUpdate;
    onPress?: OnPress;
    onFocus?: OnFocusBlur;
    onBlur?: OnFocusBlur;
  }>
>;

export const Teaser = memo(
  forwardRef<TeaserRef, TeaserProps>(
    (
      {
        index,
        imageUrl,
        ranking = false,
        opacity,
        children,
        hasTVPreferredFocus,
        onPress,
        onFocus,
        onBlur,
        onMount,
        onUpdate,
        $width = TEASER_WIDTH,
        $height = TEASER_HEIGHT,
        ...otherProps
      },
      propRef
    ) => {
      const { ref, focusKey, focused, focusSelf } = useFocusable({
        onEnterRelease: () => onPress?.({ index }),
        onFocus: (layout, _, details) => {
          onFocus?.({ layout, focusKey, index, details });
        },
        onBlur: (layout, _, details) => {
          onBlur?.({ layout, focusKey, index, details });
        },
      });

      useDidMount(() => {
        onMount?.({ focusKey, index });
      });

      useEffect(() => {
        if (hasTVPreferredFocus) focusSelf();
      }, [focusSelf, hasTVPreferredFocus]);

      const url = setImageDimensions(imageUrl, TEASER_WIDTH, TEASER_HEIGHT);

      const dimensions = { $width, $height };

      return (
        <Container ref={setComponentRefs(ref, propRef)} $opacity={opacity} {...otherProps}>
          <Content>
            <Image focused={focused} ranking={ranking} src={url} {...dimensions} />
            <Children focused={focused}>{children}</Children>
          </Content>
          <FocusIndicator focused={focused} />
        </Container>
      );
    }
  )
);

const borderRadiusAnimated = css<{ focused: boolean }>`
  transition: border-radius ${DEFAULT_ANIMATION_DURATION} ${EASE};
  border-radius: ${({ focused }) => (focused ? 0 : BORDER_RADIUS)};
`;

const Container = styled.div<{ $opacity: number | undefined }>`
  position: relative;
  opacity: ${({ $opacity }) => $opacity};
  transition: opacity ${DEFAULT_ANIMATION_DURATION} ${EASE};
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  padding: ${TEASER_FOCUS_INDICATOR_SIZE}px;
`;

const Image = styled.img<{ focused: boolean; ranking: boolean; $width: number; $height: number }>`
  object-fit: cover;
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  box-shadow: ${({ ranking }) => ranking && 'rgba(227, 31, 43, 0.8) 1px 1px 18px'};
  ${borderRadiusAnimated}
`;

const Children = styled.div<{ focused: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin: ${TEASER_FOCUS_INDICATOR_SIZE}px;
  overflow: hidden;

  ${borderRadiusAnimated}
`;

const FocusIndicator = styled.div<{ focused: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-width: ${TEASER_FOCUS_INDICATOR_SIZE}px;
  border-style: solid;
  border-color: rgba(255, 255, 255, ${({ focused }) => (focused ? 1 : 0)});
  transition: border-color ${DEFAULT_ANIMATION_DURATION} ${EASE} ${({ focused }) => (focused ? '200' : '0')}ms;
  border-radius: ${BORDER_RADIUS};
`;
