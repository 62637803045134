const { width, height } = APP_WINDOW;

export const BASE_WIDTH = 1920;
export const BASE_HEIGHT = 1080;
export const RATIO_16_9 = 16 / 9;
export const RATIO = 9 / 16;

export const RATIO_HORIZONTAL_MARGIN = (APP_WINDOW.initialWidth - APP_WINDOW.width) / 2;
export const RATIO_VERTICAL_MARGIN = (APP_WINDOW.initialHeight - APP_WINDOW.height) / 2;

export const WINDOW_WIDTH = APP_WINDOW.width;
export const WINDOW_HEIGHT = APP_WINDOW.height;

const normalizedWidth = width / BASE_WIDTH;
const normalizedHeight = height / BASE_HEIGHT;

type ScaleUnit = 'px';

const scale = (value: number, normalizedValue: number) =>
  Number.isNaN(value) || !Number.isFinite(value) ? 0 : Math.round(value * normalizedValue);

const scaleWithUnit = (value: number, normalizedValue: number, unit: ScaleUnit) => scale(value, normalizedValue) + (unit || '');

export function scaleX(value: number, unit?: ScaleUnit): string;
export function scaleX(value: number, unit: null): number;
export function scaleX(x: number, unit: ScaleUnit | null = 'px') {
  return unit ? scaleWithUnit(x, normalizedWidth, unit) : scale(x, normalizedWidth);
}

export function scaleY(value: number, unit?: ScaleUnit): string;
export function scaleY(value: number, unit: null): number;
export function scaleY(y: number, unit: ScaleUnit | null = 'px') {
  return unit ? scaleWithUnit(y, normalizedHeight, unit) : scale(y, normalizedHeight);
}
export const scaleFont = scaleX;
