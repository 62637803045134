import { createContext } from 'react';

import { useSafeContext } from '@srgssr/rio-common/react';

performanceMonitor.markOnce = (name) => {
  const marked = performanceMonitor.markers[name];
  if (!marked) {
    performanceMonitor.markers[name] = performance.now();
  }
  return !marked;
};

performanceMonitor.measure = (start, end) => performanceMonitor.markers[end] - performanceMonitor.markers[start];

type Result = { [key: string]: number | Result };

export type PerformanceMonitor = {
  result: Result | undefined | null;
  end: () => void;
};

export const PerformanceMonitorContext = createContext<PerformanceMonitor>(undefined!);
export const usePerformanceMonitor = () => useSafeContext(PerformanceMonitorContext, 'PerformanceContext');
