import { lazy } from 'react';

export enum PerformanceMonitorStaticMarkers {
  'index.html:init' = 'index.html:init',
  'bundle:init' = 'bundle:init',
  'react:init' = 'react:init',
  'store:rehydrated' = 'store:rehydrated',
  'store:ready' = 'store:ready',
  'config:ready' = 'config:ready',
  'fonts:loaded' = 'fonts:loaded',
}

export type PerformanceMonitorMarkers = PerformanceMonitorStaticMarkers | string;

export const monitoredLazy = <T extends React.ComponentType<any>>(marker: string, factory: () => Promise<{ default: T }>) =>
  lazy(() => {
    performanceMonitor.markOnce(`bundle:${marker}:loading:start`);
    return factory().then((module) => {
      performanceMonitor.markOnce(`bundle:${marker}:loading:end`);
      return { default: module.default };
    });
  });
