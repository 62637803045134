import { createGlobalStyle } from 'styled-components';

import { colors } from './utils/colors';

export const GlobalStyle = createGlobalStyle`
  html {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  * {
    box-sizing: inherit;
  }

  #root {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${colors.srgBlack};
    overflow: hidden;

    &.ready {
      display: block;
    }
  }
`;
