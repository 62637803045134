import { satisfies } from 'compare-versions';
import UAParser from 'ua-parser-js';

export const USER_AGENT = new UAParser(navigator.userAgent).getResult();

export const UPC_EOS = USER_AGENT.ua?.includes(';PC=EOS');
export const UPC_EOS1 = USER_AGENT.ua?.includes(';PC=EOSSTB'); // TODO Double check with device
export const UPC_EOS2 = USER_AGENT.ua?.includes(';PC=EOS2STB');
export const UPC_APOLLO1 = USER_AGENT.ua?.includes(';PC=APL');
export const UPC_APOLLO2 = USER_AGENT.ua?.includes(';PC=APPSTB');
export const UPC_ANY = UPC_EOS || UPC_APOLLO1 || UPC_APOLLO2;

export const TIZEN_ANY = USER_AGENT.os.name === 'Tizen';

export const isTizen5 = () => USER_AGENT.os.name === 'Tizen' && USER_AGENT.os.version && satisfies(USER_AGENT.os.version, '^5.0 || <5.0');
export const isTizen6 = () => USER_AGENT.os.name === 'Tizen' && USER_AGENT.os.version && satisfies(USER_AGENT.os.version, '^6.0 || <6.0');

export const isChrome74 = () =>
  USER_AGENT.browser.name === 'Chrome' && USER_AGENT.browser.version && satisfies(USER_AGENT.browser.version, '^74.0.0 || <74.0.0');
export const isChrome85 = () =>
  USER_AGENT.browser.name === 'Chrome' && USER_AGENT.browser.version && satisfies(USER_AGENT.browser.version, '^85.0.0 || <85.0.0');

export const isOpera46 = () =>
  USER_AGENT.browser.name === 'Opera' && USER_AGENT.browser.version && satisfies(USER_AGENT.browser.version, '^46.0.0 || <46.0.0');

export const IS_SAFARI = USER_AGENT.browser.name?.includes('Safari') && !UPC_ANY && !TIZEN_ANY;
