export const colors = {
  srgRed: '#AF001D',
  srgBtnRed: 'rgba(173, 6, 36, 0.7)',
  srgLightRed: '#E31F2B',
  srgWhite: '#fff',
  srgWhite900: 'rgba(255, 255, 255, 0.9)',
  srgWhite850: 'rgba(255, 255, 255, 0.85)',
  srgWhite700: 'rgba(255, 255, 255, 0.7)',
  srgWhite600: 'rgba(255, 255, 255, 0.6)',
  srgWhite500: 'rgba(255, 255, 255, 0.5)',
  srgWhite300: 'rgba(255, 255, 255, 0.3)',
  srgGray: '#B7B7B7',
  srgDarkGray: '#333',
  srgBlack: '#000',
  srgBlack300: 'rgba(0, 0, 0, 0.3)',
  srgBlack200: 'rgba(0, 0, 0, 0.2)',
  srgBackground: '#1B0F0F',
  srgPurpleBackground: '#0B030A',
  black: '#000',
  black500: 'rgba(0, 0, 0, 0.5)',
  transparent: 'transparent',
};
